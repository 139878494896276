<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :filters="filters" :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnPayableReceivableCreate" title="Pagar e Receber" classIcon="fas fa-plus-circle" type="primary"
          size="small" eventName="productCreate" :clicked="create" />
        <SideBar title="Pagar e Receber">
          <div v-if="sideBar.open">
            <PayableReceivableCreateUpdate :id="id" />
          </div>
        </SideBar>
      </div>
      <div slot="content-between-search-table">
        <div class="text-center">
          <div class="side-by-side">
            <ButtonToggle titleActive="Todas" titleInactive="Todas" colorActive="#3D4EAE"
              classIconActive="fa-regular fa-check-double" classIconInactive="fa-sharp fa-solid fa-list-check"
              colorInactive="#F0F0F0" :disabled="propsParam.today" v-model="propsParam.all" />
          </div>
          <div class="side-by-side div-margin-btn">
            <ButtonToggle titleActive="Hoje" titleInactive="Hoje" colorActive="#FF8A1B"
              classIconActive="fa-regular fa-check-double" classIconInactive="fa-solid fa-calendar-week"
              colorInactive="#F0F0F0" :disabled="propsParam.all" v-model="propsParam.today" />
          </div>
          <div class="side-by-side div-margin">
            <DateYearMonth :disabled="disabled" mode="year" v-model="propsParam.year" />
          </div>
          <div class="side-by-side div-margin">
            <DateYearMonth :disabled="disabled" mode="month" v-model="propsParam.month" />
          </div>
        </div>
        <br>
      </div>
      <div slot="content-buttons-table-header">
        <div class="side-by-side">
          <div v-if="hasRule('Billet')">
            <Button _key="btnOpenBillet" :title="'Boleto Bancário'" classIcon="fa-solid fa-barcode" type="primary"
              size="small" :clicked="openBillet" />
          </div>
        </div>
      </div>
    </ViewTemplateWithTable>

    <Modal title="Tem certeza que deseja excluir?" :width="500" v-show="showModal('removeRent')">
      <Alert type="warning">
        <span>
          Atenção, todos os registros relacionados também serão
          excluídos/cancelados, esta ação é irreversível!
        </span>
      </Alert>
      <Confirmation :isModal="false" title="Deseja excluir?" type="danger" :confirmed="remove" />
    </Modal>
    <Modal :title="title" :width="900" :onHideModal="onHideModal" v-show="showModal('openBillet')">
      <Billet origin="payableReceivable" :selected="selected" v-if="showModalBillet" v-model="selectedOption" />
    </Modal>

    <PrintPreview v-if="printFinance" module="Finance" :payableReceivableId="payableReceivableId"
      :onHideModal="onHideModal">
    </PrintPreview>
  </div>
</template>

<script>


import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import SideBar from "@nixweb/nixloc-ui/src/component/forms/SideBar";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple";
import PrintPreview from '../../../components/modules/adm/document/PrintPreview.vue'
import DateYearMonth from "@nixweb/nixloc-ui/src/component/forms/DateYearMonth";
import ButtonToggle from "@nixweb/nixloc-ui/src/component/forms/ButtonToggle";

import Billet from '../../../components/modules/billing/billet/Billet.vue'
import PayableReceivableCreateUpdate from "../../../components/modules/finance/payable-receivable/PayableReceivableCreateUpdate.vue";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "PayableReceivableListView",
  components: {
    ViewTemplateWithTable,
    Confirmation,
    Alert,
    Modal,
    SideBar,
    Button,
    DateTime,
    ScrollBar,
    PayableReceivableCreateUpdate,
    SelectOption,
    DateYearMonth,
    Select,
    IncrementDecrement,
    RadioGroup,
    CheckboxSimple, Billet, PrintPreview, ButtonToggle
  },
  data() {
    return {
      id: "",
      selectedOption: 0,
      panel: {
        module: "Financeiro",
        title: "Pagar e Receber",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/finance/payable-receivable/get-all",
        urlDeleteAllApi: "/api/v1/finance/payable-receivable/delete",
        showChecks: true,
        headerTable: [
          {
            field: "typeName",
            title: "Tipo",
            type: "class",
            fieldComparison: "typeName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Pagar",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Receber",
              },
            ],
          },
          {
            title: "Vencimento",
            field: "dueDate",
            type: "text",
            iconSearch: true,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "recipientName",
            fieldSecond: "description",
            title: "Descrição",
            type: "link",
            eventName: "payableReceivableUpdate",
            iconSearch: true,
          },
          {
            field: "status",
            type: "html",
          },
          {
            field: "documents",
            type: "html",
            classCssBody: "text-center",
          },
          {
            field: "tags",
            type: "html",
            classCssBody: "text-center",
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            typeButton: "dropdown",
            classCssBody: "text-center",
            styleBody: "width: 80px;",
            button: {
              title: "Mais...",
              type: "info",
              classIcon: "fa-solid fa-chevron-down",
              size: "small",
              items: [
                {
                  title: "Recibo",
                  classIcon: "fa-solid fa-file-invoice-dollar",
                  eventName: "receiptFinance",
                  hr: false,
                },
              ],
            },
          },
        ],
      },
      filters: [
        {
          type: "multiOptions",
          title: "Centro de Custo",
          field: "CostCenterName",
          url: "/api/v1/finance/cost-center/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "multiOptions",
          title: "Conta Bancária",
          field: "BankAccountName",
          url: "/api/v1/finance/bank-account/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "text",
          title: "Documento",
          field: "Document",
          value: "",
        },
        {
          type: "date",
          title: "Data de Vencimento",
          field: "_DueDate",
          value: "",
        },
        {
          type: "date",
          title: "Data de Competência",
          field: "_CompetenceDate",
          value: "",
        },
        {
          type: "options",
          title: "Pagar ou Receber",
          field: "TypeName",
          options: [
            { text: "Pagar", value: "Pagar" },
            { text: "Receber", value: "Receber" },
          ],
          value: [],
        },
        {
          type: "multiOptions",
          title: "Plano de Conta",
          field: "ChartOfAccountsName",
          url: "/api/v1/finance/chart-of-accounts/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "options",
          title: "Pago/Recebido",
          field: "PaidName",
          options: [
            { text: "Sim", value: "Sim" },
            { text: "Não", value: "Não" },
          ],
          value: [],
        },
        {
          type: "options",
          title: "Status",
          field: "Status",
          options: [
            { text: "Agendada", value: "Agendada" },
            { text: "Pago", value: "Pago" },
            { text: "Recebido", value: "Recebido" },
            { text: "Pendente", value: "Pendente" },
          ],
          value: [],
        },
        {
          type: "multiOptions",
          title: "Tipo de Pagamento",
          field: "TypePaymentName",
          url: "/api/v1/finance/type-payment/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "decimal",
          title: "Valor",
          field: "Value",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
      propsParam: {
        all: false,
        today: false,
        year: 0,
        month: 0,
      },
      showModalBillet: false,
      printFinance: false,
      payableReceivableId: ""
    };
  },
  mounted() {
    var filterStorage = this.get_FilterStorage(this.$route.name);
    if (filterStorage) {
      this.propsParam = filterStorage.propsParam;
    }
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event", "get_FilterStorage", "isExistFilterQuery"]),
    ...mapState("generic", ["sideBar", "selectStatic", "selected", "filterFinal"]),
    ...mapGetters("user", ["hasRule"]),
    disabled() {
      if (this.isExistFilterQuery(this.$route.name, '_DueDate')
        || this.isExistFilterQuery(this.$route.name, '_CompetenceDate')
        || this.propsParam.today || this.propsParam.all) {
        return true;
      } else { return false }
    },
    title() {
      if (this.selectedOption == 1) return "Emitir Boleto";
      if (this.selectedOption == 2) return "Alterar Boleto";
      if (this.selectedOption == 3) return "Cancelar Boleto";
      return "Seleciona uma opção para boleto bancário";
    }
  },
  methods: {
    ...mapMutations("generic", [
      "openModal",
      "openSideBar",
      "hideModal",
      "addEvent",
      "addFilter",
      "_addFilterStorage",
      "removeLoading",
      "executedSearch",
      "cleanSelected"
    ]),
    ...mapActions("generic", ["deleteAllApi"]),
    create() {
      this.id = "";
      this.openSideBar();
      this.removeLoading(["btnPayableReceivableCreate"]);
    },
    remove() {
      let params = {
        url: this.templateList.urlDeleteAllApi,
        selected: [this.id],
      };

      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("removeRent");
        }
        this.removeLoading(["confirm"]);
      });
    },
    openBillet() {
      this.showModalBillet = true;
      this.openModal("openBillet");
      this.removeLoading(["btnOpenBillet"]);
    },
    onHideModal() {
      this.showModalBillet = false;
      this.printFinance = false;
    },
    today() {
      this.propsParam.today = this.propsParam.today == true ? false : true;
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "removeRent") {
          this.id = event.data.id;
          this.openModal("removeRent");
        }
        if (event.name == "payableReceivableUpdate") {
          this.id = event.data.id;
          this.openSideBar();
        }

        if (event.name == "canceledBillet" || event.name == "updatedBillet" || event.name == "generatedPdfBillet") {
          this.cleanSelected();
          this.hideModal();
          this.showModalBillet = false;
        }

        if (event.name == "receiptFinance") {
          this.printFinance = true;
          this.payableReceivableId = event.data.id;
        }

        if (event.name == "emailSent") {
          this.printFinance = false;
        }
      },
      deep: true,
    },
    propsParam: {
      handler() {
        this.executedSearch();
        this._addFilterStorage({ routeName: this.$route.name, propsParam: this.propsParam });
      },
      deep: true,
    },
  },
};
</script>

<style>
.badge-default {
  background-color: #eeeeee;
  font-size: 13px !important;
  border-radius: 50px !important;
}

.box-finance {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 8px;
}

.div-btn-today {
  margin-top: 38px;
  width: 130px;
}

.finance-delay {
  background-color: red;
}

.finance-received {
  color: #009183;
  margin-right: 5px;
}

.finance-paid {
  color: #834140;
  margin-right: 5px;
}

.finance-today {
  background-color: #ff8a1b;
}

.finance-scheduled {
  background-color: #3d4eae;
}

.payable {
  color: red;
}

.receivable {
  color: darkblue;
}

.paid {
  color: #834140;
}

.received {
  color: #009183;
}

.div-legend {
  margin-top: 15px;
}

.magin {
  margin-right: 15px;
}

.div-margin {
  margin-left: 30px;
}

.div-margin-btn {
  margin-left: 15px
}

.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}

.showAll {
  margin-bottom: 10px;
}
</style>
